<template>
  <createTable
  ref="createTable"
  :operationColWidth="150"
  :autoWidth="true"
  :loading="loading"
  :pageVisible="false"
  :tableData="tableData"
  :tableDataItem="tableDataItem"
  @handleClick="handleClick"
  @forwardToPage="forwardToPage">
    <template #rightBtn="scope">
      <el-button class="margintop10" :class="item.action === 'view' ? 'operate_view' : ''" v-for="(item, index) in scope.row.member_actions.default_actions" :key="'default_actions' + index" :type="item.type && item.type === 'warn' ? 'danger' : 'primary'" size="mini" @click="handleClick(item, scope.row)">{{item.name}}</el-button>
       <el-button class="margintop10" v-for="(item, index) in scope.row.member_actions.custom_actions" :key="'custom_actions' + index" :type="item.type && item.type === 'warn' ? 'danger' : 'primary'" size="mini" @click="handleCusClick(item)">{{item.name}}</el-button>
    </template>
  </createTable>
</template>

<script>
import apis from '@/services'
import createTable from '@/components/createTable'
import jsonData from '../jsonData'
import { mixin } from '@/mixins/mixin'

export default {
  components: { createTable },
  mixins: [mixin],
  data () {
    return {
      dialogFormVisible: false,
      tableDataItem: jsonData.evses
    }
  },
  created () {
    this.queryData(apis.AssetApi.getEvsesSetup)
    this.search()
  },
  methods: {
    search () {
      this.searchData.station_charger_id_eq = this.$route.params.id
      this.loading = true
      apis.AssetApi.getEvsesList(this.searchData).then(res => {
        if (res.data) {
          this.tableData = res.data.objects
          this.tabData = res.data.scopes
        }
      }).finally(() => {
        this.loading = false
      })
    },
    // 点击操作按钮
    handleClick (type, data) {
      if (type.action === 'create') {
        this.$router.push({
          name: 'EVSEsAdd'
        })
      } else if (type.action === 'view') {
        this.$router.push({
          name: 'EVSEsDetail',
          params: {
            id: data.id.value
          }
        })
      } else if (type.action === 'edit') {
        this.$router.push({
          name: 'EVSEsAdd',
          params: {
            id: data.id.value
          }
        })
      } else if (type.action === 'destroy') {
        this.deleteClick(data, apis.AssetApi.deleteEvsesRecord)
      }
    },
    forwardToPage (data, name) {
      // if (data.model_name && data.model_name === 'charge_point') {
      //   this.$router.push({
      //     name: 'chargingPointDetail',
      //     params: {
      //       id: data.id
      //     }
      //   })
      // }
    }
  }
}
</script>
<style lang="scss" scope>

</style>
