<template>
  <div class="managerUserOrderView">
    <el-row :gutter="20" class="detail__row">
      <el-col :xs="24" :sm="24" :md="24" :lg="16" :xl="16">
        <el-card class="detail__top detail__right">
          <div class="right__box">
            <span class="detail__title">System Status:</span>
            <span class="detail__status">Enabled</span>
            <span class="detail__title margin__top12">Connection:</span>
            <div class="detail__time">
              <span class="first" v-if="custName.network_status && custName.network_status.value" :class="{right: custName.network_status.value !== 'LONG-TERM UNAVAILABLE'}">{{custName.network_status.value}}</span>
              <span class="sec" v-if="custName.updated_at">{{custName.updated_at.value}}</span>
            </div>
            <span class="detail__title margin__top12">Hardware Status:</span>
            <span v-if="custName.hardware_status" class="detail__error">{{custName.hardware_status.value}}</span>
          </div>
          <div class="left__box" style="cursor: pointer;" v-if="custName.evses && custName.evses.value && custName.evses.value.clickables">
            <div v-for="(item, index) in custName.evses.value.clickables" :key="item.id" @click="forwardToPage(item)">
              <span class="detail__title">{{`EVSE ${index + 1},ID:`}}</span>
              <span class="left__label">{{item.name}}</span>
            </div>
          </div>
        </el-card>
      </el-col>
      <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8">
        <el-card class="detail__top">
          <div class="detail__select">
            <el-select v-model="value" size="medium" placeholder="please select">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </div>
          <div class="detail__box margin__top20">
            <span class="detail__title">Charging Sessions:</span>
            <span class="detail__content" v-if="custName.charge_sessions_count">{{custName.charge_sessions_count.value}}</span>
          </div>
          <div class="detail__box margin__top12">
            <span class="detail__title">Total Energy:</span>
            <span class="detail__content" v-if="custName.total_energy">{{`${custName.total_energy.value} kWhs`}}</span>
          </div>
        </el-card>
      </el-col>
    </el-row>
    <div class="operate__wrapper">
      <el-select v-model="value1" placeholder="Select Action">
        <el-option v-for="item in options1" :key="item.value" :label="item.label" :value="item.value"></el-option>
      </el-select>
      <el-button type="primary" icon="el-icon-caret-right" @click="handleOperate"></el-button>
    </div>
    <el-card>
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="General" name="general">
          <custome-table tableType="object" :custSpan="6" :data="custName" :dataItem="jsonData[activeName]" @forwardToPage="forwardToPage"></custome-table>
        </el-tab-pane>
        <el-tab-pane label="OCPP" name="occp">
          <custome-table tableType="object" :custSpan="6" :data="custName" :dataItem="jsonData[activeName]"></custome-table>
        </el-tab-pane>
        <el-tab-pane label="EVSEs" name="evses">
          <evsesVue></evsesVue>
        </el-tab-pane>
      </el-tabs>
    </el-card>

    <el-dialog title="Set Charging Profile" width="80%" v-if="dialogFormVisible" :visible.sync="dialogFormVisible">
      <chargingSchedule></chargingSchedule>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">Cancel</el-button>
        <el-button type="primary" @click="hanleMessage(); dialogFormVisible = false">Set</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import loading from '@/utils/loading.json'
import apis from '@/services'
import customeTable from '@/components/customeTable'
import evsesVue from './component/evses.vue'
import jsonData from './jsonData'
import { mixin } from '@/mixins/viewDiolog'
import chargingSchedule from '../add/component/chargingSchedule.vue'

export default {
  components: { customeTable, chargingSchedule, evsesVue },
  mixins: [mixin],
  data () {
    return {
      custName: {},
      activeName: 'general',
      jsonData,
      dialogFormVisible: false,
      options: [{
        value: 'm',
        label: 'Month To Date'
      }],
      value: 'm',
      options1: [{
        value: 'Reset',
        label: 'Reset'
      }, {
        value: 'Update Firmware',
        label: 'Update Firmware'
      }, {
        value: 'Set Charging Profile',
        label: 'Set Charging Profile'
      }, {
        value: 'Get Composite Schedule',
        label: 'Get Composite Schedule'
      }, {
        value: 'Get Diagnostics',
        label: 'Get Diagnostics'
      }, {
        value: 'Triggle Message',
        label: 'Triggle Message'
      }, {
        value: 'Clear cache',
        label: 'Clear cache'
      }, {
        value: 'Set Security Profile',
        label: 'Set Security Profile'
      }, {
        value: 'Reset Security Profile',
        label: 'Reset Security Profile'
      }, {
        value: 'Send DataTransfer message',
        label: 'Send DataTransfer message'
      }, {
        value: 'Create Template',
        label: 'Create Template'
      }, {
        value: 'Change Availability',
        label: 'Change Availability'
      }, {
        value: 'Disconnect',
        label: 'Disconnect'
      }, {
        value: 'Export Charge Points',
        label: 'Export Charge Points'
      }],
      value1: ''
    }
  },
  created () {
    this.init()
  },
  methods: {
    // 初始化
    async init () {
      this.$store.state.app.loading = this.$loading(loading)
      const res = await apis.AssetApi.getChargePointtails(this.$route.params.id)
      if (res.status === 200) {
        this.custName = res.data.charge_point
        this.evses = [{
          evses: res.data.charge_point.evses
        }]
      }
      this.$store.state.app.loading.close()
    },
    async handleClick (type, data) { },
    forwardToPage ({ model_name, id }, name) {
      if (model_name && model_name === 'location') {
        this.$router.push({ name: 'locationDetail', params: { id } })
      }
      if (model_name && model_name === 'evse') {
        this.$router.push({ name: 'EVSEsDetail', params: { id } })
      }
    },
    handleOperate () {
      if (this.value1 === '') {
        this.$message({
          message: 'Please Select Action.',
          type: 'error'
        })
        return
      }
      if (this.value1 === 'Set Charging Profile') {
        this.dialogFormVisible = true
      } else {
        this.hanleMessage()
      }
    },
    hanleMessage () {
      this.$store.state.app.loading = this.$loading(loading)
      setTimeout(() => {
        this.$store.state.app.loading.close()
        this.$message({
          message: 'The charging point is currently unavailable.',
          type: 'error'
        })
      }, 500)
    }
  }
}
</script>
<style lang="scss" scope>
.managerUserOrderView {
  .margin__top12 {
    margin-top: 12px
  }
  .margin__top20 {
    margin-top: 24px
  }
  .detail__row {
    .el-col {
      margin-bottom: 20px;
    }
    .detail__top {
      border-radius: 8px;
      min-height: 200px;
      .detail__title {
        color: #999;
        font-size: 1rem;
        margin-bottom: 2px;
        font-variant-numeric: tabular-nums;
      }
    }
    .detail__right {
      .el-card__body {
        display: flex;
      }
      .right__box {
        display: flex;
        flex-direction: column;
        flex: 1;
        .detail__status {
          font-size: 1.2rem;
          font-weight: 600;
          color: rgb(24, 145, 104);
          line-height: 20px;
        }
        .detail__time {
          display: flex;
          align-items: center;
          .right {
            background-color: rgb(173, 255, 47, .2) !important;
            color: rgb(24, 145, 104) !important;
          }
          .first {
            font-size: 1rem;
            display: inline-block;
            border-radius: 20px;
            background-color: rgb(229, 190, 190);
            color: rgb(233, 48, 48);
            font-weight: 500;
            padding: 4px 8px;
          }
          .sec {
            font-size: 1rem;
            margin-left: 4px;
            color: #666;
          }
        }
        .detail__error {
          font-size: 1.2rem;
          color: #666;
        }
      }
      .left__box {
        flex-shrink: 0;
        width: 300px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        border-left: 1.5px solid #EBEEF5;
        padding-left: 16px;
        > div {
          margin-bottom: 2px;
          &:last-of-type {
            margin-bottom: 0;
          }
        }
        .left__label {
          font-weight: 500;
          color: #409eff;
          margin-left: 4px;
          cursor: pointer;
        }
        @media screen and (max-width: 1200px) {
          width: 200px;
        }
      }
    }
    .detail__select {
      display: flex;
      align-items: flex-end;
      text-align: end;
      justify-content: flex-end;
      .el-select {
        width: 150px;
      }
    }
    .detail__content {
      font-weight: 600;
    }
    .detail__box {
      display: flex;
      flex-direction: column;
    }
  }
  .operate__wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 12px;
    .el-select {
      // border: 1.5px solid #409eff;
      border-radius: 6px;
      width: 200px;
      margin-right: 4px;
    }
    .el-select .el-input.is-focus .el-input__inner {
      border-color: #409eff;
    }
    .el-select .el-input__inner:focus {
      border-color: #409eff;
    }
  }
}
</style>
