import Utils from '@/utils/Utils'
import _ from 'lodash'
import loading from '@/utils/loading.json'

// import { mapState } from 'vuex'
import { getDeliverDate } from '@/services/common.js'

export const mixin = {
  data () {
    return {
      // tableHeight: undefined
      searchFormData: [],
      dropDataItem: [],
      selectionData: [],
      tabData: [],
      tableData: [],
      loading: false,
      pageData: {
        page: 1,
        per_page: 50
      },
      dialogData: [],
      dialogVisible: false,
      searchData: {},
      operateVisible: true,
      batchData: undefined,
      ids: [],
      dateArray: [],
      forceable: false,
      isActive: false,
      fromRouter: ''
    }
  },
  computed: {
    // ...mapState({
    //   loading: state => state.app.loading
    // })
  },
  watch: {
    'pageData.page': {
      handler () {
        this.search()
      }
    },
    'pageData.per_page': {
      handler () {
        this.search()
      }
    }
  },
  created () {
  },
  mounted () {
  },
  methods: {
    async queryData (api, data = {}) {
      this.$store.state.app.loading = this.$loading(loading)
      const res = await api(data)
      this.$store.state.app.loading.close()
      if (res.data) {
        this.tabData = res.data.setup.scopes
        this.dropDataItem = res.data.setup.batch_actions
        this.deepDropItem = _.cloneDeep(this.dropDataItem)
        this.member_actions = res.data.setup.member_actions
        res.data.setup.sort_options && res.data.setup.sort_options.length > 0 && res.data.setup.sort_options.forEach(sort => {
          const index = this.tableDataItem.findIndex(item => item.prop === sort.name)
          if (index !== -1) {
            Object.assign(this.tableDataItem[index], {
              sortable: false,
              values: sort.key_name
            })
          }
        })
        if (res.data.setup.column_titles && res.data.setup.column_titles.length > 0) {
          this.tableDataItem.forEach((col, colNum) => {
            col.label = res.data.setup.column_titles[colNum]
          })
          const name = res.data.setup.column_titles[res.data.setup.column_titles.length - 1]
          if (name !== '操作' && name !== 'Actions') this.operateVisible = false
        }
        this.searchFormData = res.data.setup.filters.map(item => {
          return Object.assign(item, { span: 4 })
        })
        if (this.fromRouter === 'home' && this.$route.query.scope) {
          for (const i in this.tabData) {
            if (this.tabData[i].value === this.$route.query.scope) {
              this.$refs.searchForm.activeName = String(i)
            }
          }
          Object.assign(this.$refs.searchForm.createData, { scope: this.$route.query.scope })
        }
        this.queryOpearte()
      }
      this.$nextTick(() => {
        this.$refs.searchForm && this.$refs.searchForm.onSubmit()
      })
    },
    queryOpearte () {},
    // 排序
    handleSort (val) {
      this.$refs.searchForm.onSubmit(val)
    },
    // 删除数据
    deleteClick (data, delApi, queryApi) {
      // mock操作
      this.$confirm(this.$t('text.confirmDeleteThisItem'), this.$t('text.tips'), {
        confirmButtonText: this.$t('text.confirmation'),
        cancelButtonText: this.$t('button.cancel'),
        type: 'warning'
      }).then(() => {
        this.$store.state.app.loading = this.$loading(loading)
        delApi(data.id.value).then(res => {
          this.$store.state.app.loading.close()
          this.$message({
            type: 'success',
            message: this.$t('message.operateSuccess')
          })
          this.search()
        }).catch(() => this.$store.state.app.loading.close()).finally(() => {
          this.$store.state.app.loading.close()
        })
      }).catch(() => {
      })
    },
    // 数据的选择
    selectionChange (val) {
      this.selectionData = val
    },
    async handleCusClick (data, row) {
      this.batchData = data
      if (data.extra_params && data.extra_params.length > 0) {
        this.dialogData = data.extra_params
        const index = data.extra_params.findIndex(item => item.key === 'delivery_date')
        if (index !== -1 && row) {
          const value = await getDeliverDate(row.id.value)
          // this.dateArray.contact(shop.host_week_delivery_time_slots)
          if (row.shop_payment_order_type && row.shop_payment_order_type.value === 'wholesale') {
            if (value.data.shop.week_delivery_time_slots) {
              this.dateArray = value.data.shop.week_delivery_time_slots
            }
          }
          if (row.shop_payment_order_type && row.shop_payment_order_type.value === 'retail') {
            if (value.data.shop.host_week_delivery_time_slots) {
              this.dateArray = value.data.shop.host_week_delivery_time_slots
            }
          }
        }
        this.forceable = !!data.forceable
        this.dialogVisible = true
      } else {
        Utils.custOperate(data, this)
      }
      // Utils.custOperate(data, this)
    },
    // 操作已选项
    chooseDropItem (data) {
      this.batchData = data
      this.ids = []
      this.selectionData.forEach(item => {
        this.ids.push(item.id.value)
      })
      if (data.extra_params && data.extra_params.length > 0) {
        this.dialogData = data.extra_params
        this.dialogVisible = true
      } else {
        Utils.custOperate(this.batchData, this, { ids: this.ids })
      }
    },
    doNextConfirm (data) {
      Utils.custOperate(this.batchData, this, { ids: this.ids }, data)
    },
    // tab切换
    tabChange (val) {
      const index = this.dropDataItem.findIndex(item => item.value === this.tabData[val].value)
      const data = _.cloneDeep(this.deepDropItem)
      if (index !== -1) {
        data.splice(index, 1)
        this.dropDataItem = data
      } else {
        this.dropDataItem = data
      }
    },
    jmpRouter (path, data) {
      this.$router.push({
        path,
        params: { data }
      })
    },
    handleNewTab (data, name) {
      const newpage = this.$router.resolve({
        name: name,
        params: {
          id: data.id.value
        }
      })
      window.open(newpage.href, '_blank')
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.fromRouter = from.name
    })
  }
}
