import i18n from '@/i18n/index'

export default {
  formData: [{
    span: 24,
    prop: 'enabled',
    name: 'checkbox',
    label: i18n.t('Enabled'),
    placeholder: 'Enabled',
    Object: {
    }
  }, {
    span: 16,
    prop: 'charge_point_type',
    name: 'select',
    options: [{ name: 'Commercial Public', value: 'Commercial Public' }, { name: 'Commercial Private', value: 'Commercial Private' }, { name: 'Personal(home)', value: 'Personal(home)' }],
    label: i18n.t('Charge Point Type'),
    placeholder: i18n.t('Choose an option'),
    Object: {}
  }, {
    span: 16,
    prop: 'name',
    name: 'input',
    label: i18n.t('name'),
    placeholder: i18n.t('name'),
    Object: {
    }
  }, {
    span: 16,
    prop: 'location_id',
    name: 'select',
    label: i18n.t('Location'),
    placeholder: i18n.t('Location'),
    Object: {
      value: 'id',
      name: 'name',
      filterable: true
    }
  }],
  formData_ocpp: [{
    span: 16,
    prop: 'managed_by_operator',
    name: 'checkbox',
    label: i18n.t('Managed by Operator'),
    placeholder: 'Managed by Operator',
    Object: { }
  }, {
    span: 16,
    prop: 'charge_point_id',
    name: 'input',
    label: i18n.t('Charge Point ID'),
    placeholder: i18n.t('Charge Point ID'),
    Object: {
    }
  }, {
    span: 16,
    prop: 'ocpp_protocol',
    name: 'select',
    options: [
      { value: 'OCPP 1.5', name: 'OCPP 1.5' },
      { value: 'OCPP 1.6', name: 'OCPP 1.6' },
      { value: 'OCPP 1.6 SOAP', name: 'OCPP 1.6 SOAP' },
      { value: 'OCPP 2.0.1', name: 'OCPP 2.0.1' }
    ],
    value: [],
    label: i18n.t('Network Protocol'),
    placeholder: i18n.t('Network Protocol'),
    Object: {
    }
  }, {
    span: 16,
    prop: 'serial_number',
    name: 'input',
    label: i18n.t('Network Password'),
    placeholder: i18n.t('Network Password'),
    Object: {
    }
  }, {
    span: 16,
    prop: 'start_stop_from_app',
    name: 'checkbox',
    value: false,
    label: i18n.t('Start/stop chargingh from the app'),
    placeholder: 'Start/stop chargingh from the app',
    Object: {
    }
  }, {
    span: 16,
    prop: 'send_meter_update',
    name: 'checkbox',
    value: false,
    label: i18n.t('Sends periodic meter updates'),
    placeholder: 'Sends periodic meter updates',
    Object: {
    }
  }, {
    span: 16,
    prop: 'stop_unplugged',
    name: 'checkbox',
    value: false,
    label: i18n.t('Stops session if cable is unplugged from the EVSE socket'),
    placeholder: 'Stops session if cable is unplugged from the EVSE socket',
    Object: {
    }
  }, {
    span: 16,
    prop: 'plug_without_authorization',
    name: 'checkbox',
    value: false,
    label: i18n.t('Plug&Charge Without Authoriztion'),
    placeholder: 'Plug&Charge Without Authoriztion',
    Object: {
    }
  }],
  formData_evses: [{
    span: 24,
    prop: 'evses_attributes',
    name: 'array',
    value: [],
    label: i18n.t('EVSE'),
    Object: {
      delete: '_destroy',
      'label-width': '0'
    },
    createDateFirstItem: [
      {
        span: 16,
        prop: 'qr_code',
        name: 'input',
        label: i18n.t('ID'),
        placeholder: i18n.t('ID'),
        Object: {
          label: '',
          appendBtn: true,
          icon: 'el-icon-refresh'
        }
      }, {
        span: 16,
        prop: 'evse_type',
        name: 'select',
        options: [{ name: 'AC', value: 'AC' }, { name: 'DC', value: 'DC' }],
        label: i18n.t('Type'),
        placeholder: i18n.t('Type'),
        Object: {}
      }, {
        span: 16,
        prop: 'status',
        name: 'select',
        options: [{ name: 'Enabled', value: 'Enabled' }, { name: 'Disabled', value: 'Disabled' }],
        label: i18n.t('Status'),
        placeholder: i18n.t('Status'),
        Object: {}
      }, {
        span: 16,
        prop: 'tariff_group_id',
        name: 'select',
        options: [],
        label: i18n.t('Tariff Group'),
        placeholder: i18n.t('Tariff Group'),
        Object: {
          value: 'id',
          name: 'name'
        }
      }, {
        span: 16,
        prop: 'display_max_power',
        name: 'input',
        label: i18n.t('Display Max Power (KW)'),
        placeholder: i18n.t('Display Max Power (KW)'),
        Object: {}
      }, {
        span: 16,
        prop: 'max_current',
        name: 'input',
        label: i18n.t('EVSE Max Current (A)'),
        placeholder: i18n.t('EVSE Max Current (A)'),
        Object: {}
      }, {
        span: 24,
        prop: 'allows_reservation',
        name: 'checkbox',
        value: false,
        label: i18n.t('Allows reservation'),
        placeholder: i18n.t('Allows reservation'),
        Object: {}
      }, {
        span: 16,
        prop: 'phase',
        name: 'group-radio',
        options: [{
          value: 'Single Phase',
          name: 'Single Phase'
        }, {
          value: '3 Phase',
          name: '3-Phase'
        }],
        label: i18n.t('Phases'),
        Object: {}
      }, {
        span: 16,
        prop: 'input_voltage',
        name: 'group-radio',
        options: [{
          value: 380,
          name: '380 V'
        }, {
          value: 400,
          name: '400 V'
        }, {
          value: 480,
          name: '480 V'
        }],
        label: i18n.t('EVSE Input Voltage'),
        placeholder: i18n.t('EVSE Input Voltage'),
        Object: {}
      }, {
        span: 24,
        prop: 'phase_rotation',
        name: 'group-radio',
        options: [{
          value: 'RST',
          name: 'RST'
        }, {
          value: 'RTS',
          name: 'RTS'
        }, {
          value: 'SRT',
          name: 'SRT'
        }, {
          value: 'STR',
          name: 'STR'
        }, {
          value: 'TRS',
          name: 'TRS'
        }, {
          value: 'TSR',
          name: 'TSR'
        }],
        label: i18n.t('Phase Rotation'),
        placeholder: i18n.t('Phase Rotation'),
        Object: {}
      }, {
        span: 24,
        prop: 'evse_connectors_attributes',
        name: 'array',
        value: [],
        label: i18n.t('Connectors'),
        Object: {
          delete: '_destroy',
          'label-width': '0'
        },
        createDateFirstItem: [{
          span: 16,
          prop: 'connector_type',
          name: 'select',
          options: [{
            value: 'Type 2',
            name: 'Type 2'
          }, {
            value: 'Type 3',
            name: 'Type 3'
          }, {
            value: 'CHAdeMO',
            name: 'CHAdeMO'
          }, {
            value: 'CCS 1',
            name: 'CCS 1'
          }, {
            value: 'CCS 2',
            name: 'CCS 2'
          }],
          value: [],
          label: i18n.t('Type'),
          placeholder: i18n.t('Type'),
          Object: {}
        }]
      }]
  }],
  formData_chargingSchedule: [{
    span: 16,
    prop: 'charging_profile_id',
    name: 'input',
    type: 'number',
    label: i18n.t('Charging Profile Id'),
    placeholder: i18n.t('Charging Profile Id'),
    Object: {
    }
  }, {
    span: 16,
    prop: 'charging_profile_purpose',
    name: 'select',
    options: [{ name: 'TxProfile', value: 'TxProfile' }, { name: 'TxDefaultProfile', value: 'TxDefaultProfile' }, { name: 'ChargePointMaxProfile', value: 'ChargePointMaxProfile' }],
    label: i18n.t('Charging Profile Purpose'),
    placeholder: i18n.t('Charging Profile Purpose'),
    Object: {}
  }, {
    span: 16,
    prop: 'stack_level',
    name: 'input',
    type: 'number',
    label: i18n.t('Stack Level'),
    placeholder: i18n.t('Stack Level'),
    Object: {
    }
  }, {
    span: 24,
    prop: 'charging_schedule_attributes',
    name: 'array',
    value: [],
    label: i18n.t('Charging Schedule'),
    Object: {
      delete: '_destroy',
      'label-width': '0'
    },
    createDateFirstItem: [
      {
        span: 16,
        prop: 'charging_rate_unit',
        name: 'select',
        options: [{ name: 'A', value: 'A' }, { name: 'W', value: 'W' }],
        label: i18n.t('Charging Rate Unit'),
        placeholder: i18n.t('Charging Rate Unit'),
        Object: {}
      }, {
        span: 16,
        prop: 'duration',
        name: 'input',
        type: 'number',
        label: i18n.t('Duration'),
        placeholder: i18n.t('Duration'),
        Object: {}
      }, {
        span: 16,
        prop: 'valid_date',
        name: 'date',
        type: 'daterange',
        value: [],
        label: i18n.t('Valid Date'),
        placeholder: i18n.t('Valid Date'),
        Object: {
          'start-placeholder': 'Valid From',
          'end-placeholder': 'Valid To'
        }
      }, {
        span: 24,
        prop: 'charging_schedule_period_attributes',
        name: 'array',
        value: [],
        label: i18n.t('Charging Schedule Period'),
        Object: {
          delete: '_destroy',
          'label-width': '0'
        },
        createDateFirstItem: [{
          span: 16,
          prop: 'limit',
          name: 'input',
          type: 'number',
          label: i18n.t('Limit'),
          placeholder: i18n.t('Limit'),
          Object: {}
        }, {
          span: 16,
          prop: 'start_time',
          name: 'time',
          label: i18n.t('Start Time'),
          placeholder: i18n.t('Start Time'),
          Object: {}
        }]
      }]
  }]
}
