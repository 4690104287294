// import i18n from '@/i18n/index'

export default {
  general: [
    { prop: 'id' },
    { prop: 'name' },
    { prop: 'roaming' },
    { prop: 'location' }
  ],
  occp: [
    { prop: 'hardware_status' },
    { prop: 'network_status', back: true },
    { prop: 'ocpp_protocol' },
    { prop: 'plug_without_authorization', back: true },
    { prop: 'send_meter_update', back: true },
    { prop: 'serial_number' },
    { prop: 'start_stop_from_app', back: true },
    { prop: 'stop_unplugged', back: true }
  ],
  evses: [
    { prop: 'id', label: 'ID', Object: 'value', width: '120' },
    { prop: 'charge_point', label: 'Charge Point', Object: 'value', width: '150' },
    { prop: 'display_max_power', label: 'Display Max Power', Object: 'value', width: '200' },
    { prop: 'phase', label: 'Phase', Object: 'value', width: '120' },
    { prop: 'phase_rotation', label: 'Phase Rotation', Object: 'value', width: '180' },
    { prop: 'input_voltage', label: 'Input Voltage', Object: 'value', width: '150' },
    { prop: 'max_current', label: 'Max Current', Object: 'value', width: '150' }
  ]
}
